.checkout {
  min-width: 325px;
  max-width: 400px;
  width: 90%;
}

.checkout button {
  margin-top: 20px;
  margin-bottom: 30px;
  float: right;
}

.checkout h6 {
  margin-bottom: 8px;
}

.checkout p {
  font-size: 0.9rem;
  margin-bottom: 20px;
}

/* stripe */

#card-element {
  padding: 8px 10px;
  border: 1px solid var(--color-grey-3);
  border-radius: 0;
}

/* order summary */

.payment-component {
  display: flex;
  justify-content: space-around;
}

#order-summary {
  width: 90%;
  max-width: 400px;
  min-width: 325px;
  padding: 30px;
  margin-bottom: 36px;
}

.order-summary-line {
  display: flex;
  align-items: center;
}

.order-summary-line.hide {
  display: none;
}

.order-summary-line svg {
  color: var(--color-grey-3);
  width: 20px;
  margin-right: 5px;
}

.order-summary-line p {
  color: var(--color-grey-2);
  font-size: 14px;
}

.item-price-line {
  justify-content: space-between;
  margin: 5px 0;
}

p.item-p-important {
  color: var(--color-grey-1);
}

/* Tip Option */

.os-input-button-grp {
  display: flex;
  width: 75%;
  justify-content: space-between;
}

.os-input-button-grp .MuiButton-label {
  color: var(--color-grey-2);
}

.os-input-button-grp .MuiButton-outlined.active {
  border-color: var(--color-primary-main);
}

.os-input-button-grp .MuiButton-outlined.active .MuiButton-label {
  color: var(--color-primary-main);
}

.tip-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tip-option .MuiButton-root {
  width: 30%;
}

.tip-option p,
.promo-code p {
  width: 20%;
  margin: 0;
  font-size: 13px;
}

.promo-code-wrapper {
  margin-bottom: 20px;
}

.promo-code {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

#promo-code-input {
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  padding-left: 0;
  margin: 0;
}

#promo-code-clickable {
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 14px;
}

#promo-code-clickable:hover {
  color: var(--color-secondary-main);
}

