.date-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.date-card {
  width: 80px;
  height: 80px;
  border: 2px solid var(--color-primary-main);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  cursor: pointer;
  transition: all 0.3s;
}

.date-card:first-of-type {
  margin-left: 8px;
}

.date-card:hover,
.date-card.active {
  background: var(--color-primary-main);
}

.date-card:hover p,
.date-card:hover h6,
.date-card.active p,
.date-card.active h6 {
  color: white;
}

.date-card p,
.date-card h6 {
  margin: 0;
  text-align: center;
}

.date-card p {
  font-size: 0.9rem;
  color: var(--color-grey-2);
}