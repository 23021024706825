#dashboard {
  padding: 40px;
}

#dashboard h1 {
  text-align: center;
  margin-bottom: 60px;
}

#dashboard h4 {
  color: var(--color-primary-light);
  text-decoration: underline;
}

.booking-card {
  width: 300px;
  border: 1px solid var(--color-primary-light);
  padding: 15px;
  margin-bottom: 20px;
  color: var(--color-grey-2);
}

.card-item {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.card-item p {
  margin-left: 10px;
}

.booking-card button {
  margin-top: 20px;
}