.thank-you {
  max-width: 700px;
  text-align: center;
  margin: auto;
}

.thank-you img {
  max-width: 250px;
}

.thank-you h3 {
  color: var(--color-primary-main);
  font-size: 2rem;
  margin-bottom: 30px;
}

.thank-you h4 {
  color: var(--color-primary-main);
  font-family: 'galano-reg', sans-serif;
  font-size: 1.2rem;
  margin: 30px auto 50px auto;
  max-width: 500px;
}

.thank-you p {
  color: var(--color-primary-main);
  font-size: 1.1rem;
  margin-bottom: 8px;
}

.thank-you a {
  display: inline-block;
  margin-top: 20px;
}

.alli-logo {
  height: 30px;
  margin-right: 20px;
}