.basic-card,
.double-card,
.triple-image-card {
  border: 2px solid var(--color-primary-light);
  box-shadow: 0 0 5px var(--color-primary-light);
  padding: 20px;
  min-width: 200px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.basic-card:last-of-type,
.double-card:last-of-type,
.triple-image-card:last-of-type {
  margin-bottom: 0;
}

.basic-card {
  width: 65%;
  min-width: 250px;
}

.double-card {
  width: 48%;
}

.basic-card h5,
.double-card h5 {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--color-primary-main);
}

.double-card h3 {
  color: var(--color-primary-main);
  font-size: 1.9rem;
}

.card-price {
  font-size: 1.6rem;
  margin-top: 1rem;
  font-family: 'galano-light', sans-serif;
  color: var(--color-primary-light);
}

.triple-image-card {
  width: 30%;
}

.basic-card:hover,
.addon-card:hover,
.double-card:hover,
.triple-image-card:hover {
  background: var(--color-primary-hover);
  box-shadow: none;
  transition: all 0.3s; 
}

.double-image-card img,
.triple-image-card img {
  width: 180px;
  margin-bottom: 36px;
}

.double-card:last-of-type,
.triple-image-card:last-of-type {
  margin-right: 0;
}

.double-image-card h5,
.triple-image-card h5 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: var(--color-primary-main);
}

.basic-card p,
.double-image-card p,
.triple-image-card p {
  margin-top: 12px;
  color: var(--color-grey-2);
}

.double-card-note {
  color: rgb(115, 112, 115);
  margin-top: 15px;
  font-size: 0.95rem;
}

.double-card-description {
  color: var(--color-primary-light);
  margin-top: 10px;
  font-size: 1.2rem;
}

.addon-card {
  display: flex;
  width: 100%;
  border: 2px solid var(--color-primary-light);
  padding: 15px 8px 15px 0;
  margin-bottom: 20px;
  cursor: pointer;
}

p.addon-card-duration {
  color: var(--color-primary-light);
  margin-bottom: 12px;
  font-size: 0.95rem;
}

p.addon-card-description {
  font-size: 0.9rem;
  margin-bottom: 18px;
}

.addon-card h6 {
  margin: 0;
  color: var(--color-primary-light);
}

@media only screen and (max-width: 850px) {
  .triple-image-card {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    flex-direction: row;
  }

  .triple-image-card img {
    margin-bottom: 0;
    margin-right: 10px;
  }

  .addon-card {
    width: 48%;
  }
}

@media only screen and (max-width: 550px) {
  .double-card {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .addon-card {
    width: 100%;
  }
}