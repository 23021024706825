#not-available {
  max-width: 500px;
  margin: auto;
}

#not-available p {
  margin-bottom: 20px;
}

.check-box {
  margin: 20px 0;
}

#not-available button {
  margin: 40px auto;
  display: block;
}