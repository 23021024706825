.hide {
  display: none;
}

.modal {
  max-width: 500px;
  width: 95%;
  background: var(--color-background-secondary);
  padding: 60px 25px 40px 25px;
  position: relative;
}

#close-modal-button {
  font-size: 30px;
  color: var(--color-grey-3);
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
}

#close-modal-button:hover {
  background-color: var(--color-primary-hover);
}

#password-reset-modal p {
  margin-bottom: 30px;
  color: var(--color-grey-2);
}

#password-reset-modal h6 {
  margin: 0;
  color: var(--color-grey-3);
}

#password-reset-modal .auth-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auth-header {
  height: 40px;
  position: relative;
}

#button-google {
  color: rgb(72, 72, 72);
  border: 2px solid rgb(118, 118, 118);
  width: 100%;
  height: 40px;
}

#button-google:hover {
  background-color: var(--color-primary-hover);
}

.or-divider-wrapper {
  text-align: center;
  overflow: hidden;
  padding: 10px;
}

.or-divider {
  line-height: 0.5;
  text-align: center;
  width: 100%;
}

.or-divider span{
  display: inline-block;
  position: relative;
  color: #555555;
  font-size: 14px;
}

.or-divider span::before,
.or-divider span::after {
  content: "";
  position: absolute;
  height: 5px;
  border-bottom: 1px solid #EBEBEB;
  top: 0;
  width: 275px;
}

.or-divider span::before {
  right: 100%;
  margin-right: 15px;
}

.or-divider span::after {
  left: 100%;
  margin-left: 15px;
}

.auth-footer {
  margin-top: 24px;
}

.auth-footer p {
  font-size: 14px;
}

.auth-footer .link {
  margin-bottom: 5px;
}