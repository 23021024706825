.request-form {
  max-width: 400px;
  margin: 40px auto;
}

.request-form button {
  margin: auto;
  display: block;
  margin-top: 20px;
}

.request-form h6 {
  font-family: 'galano-light';
  margin-top: 30px;
  font-size: 1rem;
}
