#covid p {
  margin-top: 20px;
}

#covid ul {
  list-style-type: square;
  font-size: 0.95rem;
}

#covid li {
  margin-left: 40px;
}

#covid .flex {
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
  cursor: pointer;
}

#covid .flex:hover {
  background-color: var(--color-primary-hover);
}

#covid .flex p {
  margin: 0;
  color: var(--color-primary-main);
}