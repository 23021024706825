:root {
  --color-primary-dark: #322030;
  --color-primary-main: #52384f;
  --color-primary-light: #C2BCC1;
  --color-primary-hover: #1f131e0a;
  --color-secondary-dark: #B7370C;
  --color-secondary-main: #CA5116;
  --color-secondary-light: #EFCBB9;
  --color-info-dark: #919CB0;
  --color-info-main: #ABB4C5;
  --color-info-light: #E6E9EE;
  --color-error-dark: #8A001D;
  --color-error-main: #A74056;
  --color-error-light: #E2BFC7;
  --color-highlight-dark: #FFD897;
  --color-highlight-main: #FFE3AF;
  --color-highlight-light: #FFF2DC;
  --color-approved-dark: #9DA89E;
  --color-approved-main: #CBD9CC;
  --color-approved-light: #F2F5F2;
  --color-grey-1: #1A1A1A;
  --color-grey-2: #808080;
  --color-grey-3: #BFBFBF;
  --color-grey-4: #DFDFDF;
  --color-background-primary: #FAF8F6;
  --color-background-secondary: #FAFAFA;
}

@font-face {
  font-family: 'galano-semibold';
  src: url('./galano/galanogrotesquealt-semibold-webfont.woff2') format('woff2'),
       url('./galano/galanogrotesquealt-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'galano-light';
  src: url('./galano/galanogrotesquealt-light-webfont.woff2') format('woff2'),
       url('./galano/galanogrotesquealt-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'galano-reg';
  src: url('./galano/GalanoGrotesque-Regular-webfont.woff2') format('woff2'),
       url('./galano/GalanoGrotesque-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'galano-bold';
  src: url('./galano/GalanoGrotesque-Bold-webfont.woff2') format('woff2'),
       url('./galano/GalanoGrotesque-Bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
  line-height: 1;
  background-color: var(--color-background-primary);
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html {
  font-size: 16px;
}

div {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
  color: var(--color-grey-1);
  margin-bottom: 20px;
  line-height: 1.2em;
}

h1 {
  font-size: 3.5rem;
  font-weight: 900;
}

h2 {
  font-size: 3rem;
  font-weight: 900;
}

h3 {
  font-size: 2rem;
  font-weight: 700;
}

h4 {
  font-size: 1.5rem;
  font-weight: 700;
}

h5 {
  font-size: 1.125rem;
  font-weight: 700;
}

h6 {
  font-size: 1.05rem;
  font-weight: 500;
}

p, a, li, span {
  font-size: 1rem;
  font-family: 'galano-light', sans-serif;
  line-height: 1.4em;
}

a {
  text-decoration: none;
  font-size: 0.9rem;
  font-family: 'galano-light', sans-serif;
  letter-spacing: .01em;
}

a:hover {
  color: var(--color-darkGray);
}

label {
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'galano-reg', sans-serif;
  margin-bottom: 5px;
  display: block;
  color: var(--color-grey-1);
}

b {
  font-family: 'galano-semibold', sans-serif;
}

.text-center {
  text-align: center;
}

.caption {
  font-size: 12px;
}

.subtitle {
  font-size: 18px;
  font-family: 'galano-light', sans-serif;
  line-height: 1.3em;
}

select,
textarea,
input {
  border-radius: 0;
  -webkit-appearance: none;
  border: 1px solid var(--color-grey-3);
  display: block;
  margin: 0 0 20px;
  background-color: transparent;
  outline: none;
  padding: 8px 10px;
  color: var(--color-grey-1);
  font-size: 12px;
  width: 100%;
  max-width: 100%;
  font-family: 'galano-light', sans-serif;
  border-radius: 0;
  padding: 8px 10px;
}

select {
  height: 34px;
}

.search:focus,
select:focus,
textarea:focus,
input:focus {
  border: 1px solid var(--color-grey-2);
  box-shadow: 0 0 5px var(--color-primary-light);
}

textarea, input {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

a.underline:hover {
  background-image: linear-gradient(180deg, var(--color-highlight-main), var(--color-highlight-main));
  background-repeat: no-repeat;
  background-position: 4px bottom;
  background-size: 100% 10px;
}

.highlight {
  background-image: linear-gradient(180deg, var(--color-highlight-main), var(--color-highlight-main));
  background-repeat: no-repeat;
  background-position: 4px bottom;
  background-size: 100% 10px;
}

.overlay {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);
  z-index: 100;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  background-color: var(--color-primay-hover);
}

.elevation-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.link {
  font-family: 'galano-light', serif;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  color: var(--color-grey-1);
}

.link:hover {
  color: var(--color-secondary-main);
}

.divider {
  margin: 20px 0;
  border-bottom: 1px solid #ebebeb;
}

/* layout */

.page {
  min-height: 100vh;
}

.flex {
  display: flex;
}

.justify-end {
  justify-content: flex-end;
}

.col {
  flex-direction: column;
}

.center {
  justify-content: center;
  align-items: center;
}

.width-50 {
  width: 50%;
  min-width: 280px;
}

.margin-30 {
  margin: 30px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.padding-20 {
  padding: 20px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-top-5 {
  margin-top: 5px;
}

.card {
  box-shadow: 1px 1px 6px 1px #ccc;
  -webkit-box-shadow: 1px 1px 6px 1px #ccc; 
}

.flex-row-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-row-end {
  display: flex;
  justify-content: flex-end;
}

.search {
  display: flex;
  align-items: center;
  border: 1px solid #bbbbbb;
  padding-right: 5px;
}

.MuiButton-root.button-center {
  display: block;
  margin: auto;
}

input.search-input {
  border: none;
  margin-bottom: 0;
}

input.search-input:focus {
  border: none;
  box-shadow: none;
}

.icon-button.MuiIcon-root {
  cursor: pointer;
  font-size: 3rem;
  color: var(--color-primary-light);
  border: 2px solid var(--color-primary-light);
  transition: all 0.5s;
}

.icon-button.MuiIcon-root:hover {
  background: var(--color-primary-light);
  color: white;
}

.icon-button-inactive.MuiIcon-root {
  font-size: 3rem;
  color: var(--color-grey-4);
  border: 2px solid var(--color-grey-4);
}

.pac-logo,
.pac-container {  
  z-index: 9999999 !important;  
  background-color: #fff;
  position: absolute!important;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial,sans-serif;
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

@media only screen and (max-width: 500px) {
  html {
    font-size: 14px;
  }

  .flex {
    flex-wrap: wrap;
  }

  .MuiDrawer-paperAnchorDockedRight {
    width: 100%;
  }
}