#time-modal-body::-webkit-scrollbar,
.time-card::-webkit-scrollbar {
  width: 0.3em;
}

#time-modal-body::-webkit-scrollbar-track,
.time-card::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
}

#time-modal-body::-webkit-scrollbar-thumb,
.time-card::-webkit-scrollbar-thumb {
background-color: var(--color-primary-main);
outline: 1px solid var(--color-primary-main);
}

#time-card-container {
  max-width: 400px;
  width: 90%;
  height: 50vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  position: relative;
  border: 1px solid var(--color-primary-main);
  z-index: 1;
  perspective: 1000;
}

#time-card-wrapper {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 0.5s linear;
}

#time-card-container.flip #time-card-wrapper {
  transform: rotateY(180deg);
}

/* #time-card-container.flip .front {
  visibility: hidden;
} */

.face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.face.back {
  display: block;
  transform: rotateY(180deg);
  box-sizing: border-box;
  text-align: center;
  background-color: transparent;
}

.face.front {
  overflow-y: auto;
}

.time-slot {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  border-bottom: 1px dashed #ddd;
}

.time-slot-time {
  margin-left: 20px;
  font-family: 'galano-light', sans-serif;
  font-size: 20px;
  color: var(--color-grey-2);
}

.time-slot-pro {
  margin-right: 10px;
  display: flex;
}

.time-slot-pro .MuiAvatar-root {
  margin-right: 8px;
  position: initial;
}

.time-slot:hover {
  background-color: var(--color-primary-hover);
}

.time-slot:last-of-type {
  border-bottom: none;
}

.time-modal-body {
  height: calc(50vh - 191px);
  overflow-y: auto;
  padding: 25px;
}

.time-modal-body p {
  color: var(--color-grey-2);
}

.time-modal-footer {
  text-align: center;
  padding-top: 10px;
  box-shadow: 0 -2px 6px rgba(153,153,153,.2);
}

.time-modal-footer .subtitle {
  margin-bottom: 20px;
}

.time-modal-footer button {
  height: 45px;
  width: 100%;
}

.time-modal-pro-wrapper {
  display: flex; 
  justify-content: center;
}

.time-modal-pro {
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  transition: all 0.5s;
}

.time-modal-pro .MuiAvatar-root {
  width: 50px;
  height: 50px;
}

.time-modal-pro.active .MuiAvatar-root {
  border: 2px solid var(--color-primary-light)
}

.time-modal-pro:hover .MuiAvatar-root {
  border: 2px solid var(--color-primary-light)
}

.time-modal-header {
  padding: 5px;
  box-shadow: 0 0 2px 0 rgba(0,0,0,.15);
}

.time-modal-header h6 {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  color: var(--color-grey-1);
}

.time-card-alert a {
  color: var(--color-secondary-main);
}

p.time-card-alert {
  color: var(--color-grey-2);
  width: 90%;
  max-width: 400px;
  margin: 20px auto;
}