.landing {
  min-height: 100vh;
  padding-bottom: 50px;
  padding-top: 120px;
  background: var(--color-primary-dark);
}

.header-wrapper {
  height: 70px;
  background-color: var(--color-background-primary);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
}

.header {
  height: 100%;
  max-width: 600px;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  color: var(--color-primary-dark);
  font-size: 1.4rem;
  font-family: "Playfair Display",serif;
  font-weight: 500;
  margin: 0;
}

.landing-body {
  border-radius: 5px;
  background: var(--color-background-primary);
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
}

#login-avatar h5 {
  color: white;
  margin: 0;
  font-size: 1.4rem;
}

.landing-footer {
  margin-top: 48px;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.landing-footer p {
  margin: 0;
  color: var(--color-grey-3);
  transition: all 0.5s;
  font-family: 'galano-semibold';
  text-transform: uppercase;
}

.landing-footer .MuiIcon-root {
  font-size: 2.5rem;
  color: #c5c5c5;
  margin-left: -0.3em;
  transition: all 0.5s;
}

.landing-footer:hover p,
.landing-footer:hover .MuiIcon-root {
  color: var(--color-secondary-main);
}

.landing .subtitle {
  color: var(--color-grey-2);
}

.landing-description {
  margin-bottom: 50px;
}

.landing h4 {
  margin-bottom: 24px;
  color: var(--color-grey-1);
  font-family: 'galano-light', sans-serif;
  font-size: 1rem;
  letter-spacing: 0.03rem;
  line-height: 1.4em;
}

.landing h3 {
  margin: 0;
  font-family: 'Playfair Display', sans-serif;
  text-align: center;
  font-weight: 300;
  font-size: 1.6rem;
  color: var(--color-primary-dark);
}

.card-wrapper {
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.card-transition-enter {
  transform: translateX(100vw);
  opacity: 0;
  position: absolute;
}
.card-transition-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 0.8s linear 0.3s;
}

.card-transition-exit {
  transform: translateX(0);
  opacity: 1;
}

.card-transition-exit-active {
  transform: translateX(-100vw);
  opacity: 0;
  transition: all 0.3s linear;
}

.landing-title-button {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.landing-title-button h4 {
  margin: 0;
}

.location-wrapper {
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

/* physio first visit */

.physio-first-visit button {
  margin: 40px auto 20px auto;
  display: block;
}

.physio-first-visit p {
  text-align: center;
}

.physio-first-visit a {
  text-align: center;
  display: block;
  margin-top: 20px;
  text-decoration: underline;
  color: var(--color-secondary-dark);
  font-family: 'galano-semibold', sans-serif;
}

@media only screen and (max-width: 550px) {
  .landing-title-button h4 {
    margin: 0;
  }
}